import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";

interface PageProps {
  title?: string;
  children: React.ReactNode;
  onBack?: () => void;
  isLoading?: boolean;
  total?: number;
}

export default function Page({ children, title, onBack, total }: PageProps) {
  return (
    <div>
      <div className="flex items-center mb-3 gap-4">
        {title && <Typography.Title level={4}>{title}</Typography.Title>}
        {total && <h3>Total: {total}</h3>}
        <div className="flex-grow"></div>
        {onBack && (
          <Button
            className="bg-primary flex items-center"
            icon={<LeftOutlined />}
            type="primary"
            onClick={onBack}
          >
            Back
          </Button>
        )}
      </div>
      {children}
    </div>
  );
}
