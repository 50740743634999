import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import { commentsApi } from "../apis";
import { queryClient } from "../libs/queryClient";

const useDeleteComment = () =>
  useMutation({
    mutationFn({ comment_id }: { comment_id: number }) {
      return commentsApi.deleteComment(comment_id);
    },
    onSuccess() {
      notification.success({ message: "Comment Deleted" });
      queryClient.invalidateQueries(["comment"]);
    },
    onError(error: Error) {
      notification.error({ message: error.message });
    },
  });

export { useDeleteComment };
