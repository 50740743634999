import { request } from "../libs/request";

const USER_ENDPOINT = "/api/user";

export const getPostById = (post_id: number, post_type: number) =>
  request<ApiResponse<Post>>("GET", `${USER_ENDPOINT}/get_post_data/`, {
    post_id,
    post_type,
  });

export const deletePost = (post_id: number, post_type: number) =>
  request<ApiResponse<any>>("DELETE", `${USER_ENDPOINT}/delete_post/`, {
    post_id,
    post_type,
  });
