import { request } from "../libs/request";

const USER_ENDPOINT = "/api/user";

export const getStoryById = (story_id: number) =>
  request<ApiResponse<StoryData>>(
    "GET",
    `${USER_ENDPOINT}/get_story_detail/?story_id=${story_id}`
  );

export const deleteStory = (story_id: number) =>
  request<ApiResponse<any>>("DELETE", `${USER_ENDPOINT}/delete_story/`, {
    story_id,
  });
