import { request } from '../libs/request';

const ADMIN_ENDPOINT = '/api/admin';

export interface GetReportsQuery {
  reported_date?: (string | null)[];
  page?: number;
  page_size?: number;
}

export type ReportType = 1 | 2 | 3 | 4;

export enum ReportTypeEnum {
  USER = 1,
  POST = 2,
  COMMENT = 3,
  STORY = 4,
}

export const getReportsSummary = () => request<ApiResponse<ReportSummary>>('GET', `${ADMIN_ENDPOINT}/reports_summary`);

export const getUsersReport = (query: GetReportsQuery) => {
  return request<ApiResponse<NewPaginatedResponse & { reports?: UsersReport[] }>>(
    'GET',
    `${ADMIN_ENDPOINT}/user_reports/`,
    query
  );
};

export const getPostsReport = (query: GetReportsQuery) => {
  return request<ApiResponse<NewPaginatedResponse & { reports?: PostsReport[] }>>(
    'GET',
    `${ADMIN_ENDPOINT}/post_reports/`,
    query
  );
}

export const getCommentsReport = (query: GetReportsQuery) => {
  return request<ApiResponse<NewPaginatedResponse & { reports?: CommentsReport[] }>>(
    'GET',
    `${ADMIN_ENDPOINT}/comment_reports/`,
    query
  );
}

export const getStoriesReport = (query: GetReportsQuery) => {
  return request<ApiResponse<NewPaginatedResponse & { reports?: StoriesReport[] }>>(
    'GET',
    `${ADMIN_ENDPOINT}/story_reports/`,
    query
  );
}

export const updateReportStatus = (id: number, status: boolean, type: ReportType) =>
  request<ApiResponse>('POST', `${ADMIN_ENDPOINT}/update_reports_status?id=${id}&status=${status}&type=${type}`);

export const deleteReportById = (id: number, type: ReportType) =>
  request<ApiResponse>('DELETE', `${ADMIN_ENDPOINT}/reports?id=${id}&type=${type}`);
