import { useEffect } from "react";

import { useAuthCtx } from "@/shared/contexts/AuthContext";

export default function Logout() {
  const { signOut } = useAuthCtx();

  useEffect(() => {
    signOut();
  }, []);

  return <></>;
}
