import MuxVideo from "@mux/mux-video-react";

type VideoProps = {
  src: string;
  poster?: string;
};
export default function Video({ src, poster }: VideoProps) {
  const muxType = src.match(/stream.mux.com\/(.+).m3u8/);
  if (muxType) {
    const playbackId = muxType[1];
    console.log({ playbackId });
    return (
      // @ts-ignore
      <MuxVideo
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
        playbackId={"DS00Spx1CV902MCtPj5WknGlR102V5HFkDe"}
        streamType="on-demand"
        controls
      />
    );
  }
  return (
    <video controls className="w-full h-full object-cover" poster={poster}>
      <source src={src}></source>
    </video>
  );
}
