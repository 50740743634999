import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import qs from "query-string";

import { env } from "@/environments";

const axiosClient = axios.create({
  baseURL: env.common.baseUrl,
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": true,
  },
  paramsSerializer: {
    serialize: (q) => qs.stringify(q),
  },
});

axiosClient.interceptors.request.use(async (config: any) => {
  const accessToken = sessionStorage.getItem(env.storage.accessToken);
  if (accessToken) {
    config.headers!.token = `${accessToken}`;
  }
  return config;
});

axiosClient.interceptors.response.use((response) => {
  const data = response.data ?? response;
  if (data?.status === "failure") {
    const error = data as ApiResponse<ApiError>;
    throw error.data;
  }
  return data;
});

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export const request = async <TData>(
  method: Method,
  url: string,
  requestData?: unknown
): Promise<TData> => {
  const config: AxiosRequestConfig = {
    method,
    url,
  };
  if (method === "POST" || method === "PATCH") {
    config.data = requestData;
  } else {
    config.params = requestData;
  }
  try {
    const response = (await axiosClient.request(config)) as ApiResponse<any>;
    return response as TData;
  } catch (e) {
    throw new Error((e as ApiError).error_message);
  }
};
