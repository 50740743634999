import { Button, Col, Form, Input, Row, Typography } from "antd";

import { loginBg } from "@/assets/images";
import { useAuthCtx } from "@/shared/contexts/AuthContext";

export default function Login() {
  const { isLoading, signIn } = useAuthCtx();
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    signIn(values, (err) => {
      if (!err) {
        form.resetFields();
        return;
      }
    });
  };

  return (
    <Row className="h-screen">
      <Col
        className="w-full h-full bg-no-repeat bg-center bg-cover"
        xs={0}
        lg={14}
        style={{
          backgroundImage: `url(${loginBg})`,
        }}
      />
      <Col
        className="flex flex-col justify-center items-center bg-gray-100"
        xs={24}
        lg={10}
      >
        <Form
          form={form}
          className="mx-auto max-w-md w-full rounded bg-white shadow p-5 space-y-5"
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Typography.Title className="text-center !text-primary" level={3}>
            Workideo dashboard
          </Typography.Title>
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button block loading={isLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
