export enum RouteKey {
  dashboard = "dashboard",
  users = "users",
  login = "login",
  logout = "logout",
  reports = "reports",
  reports_summary = "reports-summary",
  users_reports = "users-reports",
  posts_reports = "posts-reports",
  comments_reports = "comments-reports",
  stories_reports = "stories-reports",
  post = "post",
  comment = "comment",
  story = "story",
  settings = "settings",
  settings_password = "settings-password",
}

export enum UserStatus {
  all = -1,
  active = 0,
  deleted = 1,
}
