import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import { storyApi } from "../apis";
import { queryClient } from "../libs/queryClient";

const useDeleteStory = () =>
  useMutation({
    mutationFn({ story_id }: { story_id: number }) {
      return storyApi.deleteStory(story_id);
    },
    onSuccess() {
      notification.success({ message: "Story Deleted" });
      queryClient.invalidateQueries(["story"]);
    },
    onError(error: Error) {
      notification.error({ message: error.message });
    },
  });

export { useDeleteStory };
