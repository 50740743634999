import { env } from "@/environments";
import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usersApi } from "../apis";
import { AdminSignInDto, AdminSignInResponse } from "../apis/users";
import { RouteKey } from "../enums";

interface IAuthContext {
  isAuth: boolean;
  isLoading: boolean;
  signIn: (adminSignInDto: AdminSignInDto, cb?: ErrorCb) => Promise<void>;
  signOut: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuth: false,
  isLoading: false,
  signIn: async () => {},
  signOut: () => {},
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuth, setIsAuth] = React.useState(
    () => !!sessionStorage.getItem(env.storage.accessToken)
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const { mutateAsync } = useMutation<
    ApiResponse<AdminSignInResponse>,
    ApiError,
    AdminSignInDto
  >((payload) => usersApi.adminSignIn(payload));
  const navigate = useNavigate();

  const signIn = React.useCallback(
    async (adminSignInDto: AdminSignInDto) => {
      setIsLoading(true);
      try {
        const { data, message } = await mutateAsync(adminSignInDto);
        setIsAuth(true);
        sessionStorage.setItem(env.storage.accessToken, data.access_token);
        notification.success({
          message,
        });
        navigate("/");
      } catch (e: any) {
        notification.error({
          message: e?.error_message,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [notification, mutateAsync, navigate]
  );

  const signOut = React.useCallback(() => {
    sessionStorage.removeItem(env.storage.accessToken);
    setIsAuth(false);
  }, []);

  useEffect(() => {
    if (!isAuth) {
      navigate(RouteKey.login);
    }
  }, [isAuth]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        isLoading,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthCtx = () => {
  return React.useContext(AuthContext);
};
