import { useQuery } from "@tanstack/react-query";

import { interestCategoriesApi } from "../apis";

export const useInterestCategoriesQuery = () =>
  useQuery(
    ["interest_categories"],
    interestCategoriesApi.getInterestCategories,
    {
      staleTime: Infinity,
    }
  );
