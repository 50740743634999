import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import { reportsApi } from "../apis";
import { ReportTypeEnum } from "../apis/reports";
import { queryClient } from "../libs/queryClient";

const useUpdateReportStatus = () =>
  useMutation({
    mutationFn({
      ids,
      status,
      type,
    }: {
      ids: number[];
      status: boolean;
      type: reportsApi.ReportType;
    }) {
      return Promise.all(
        ids.map((id) => reportsApi.updateReportStatus(id, status, type))
      );
    },
    onSuccess(_, { status, type }) {
      notification.success({
        message: `Report ${status ? "Opened" : "Ignored"}`,
      });
      queryClient.invalidateQueries([
        Object.entries(ReportTypeEnum)
          .find((e) => e[1] === type)?.[0]
          ?.toLowerCase(),
      ]);
      queryClient.invalidateQueries(["user"]);
    },
    onError(error: Error) {
      notification.error({ message: error.message });
    },
  });
export { useUpdateReportStatus };
