import { commentsApi } from "@/shared/apis";
import { Post } from "@/shared/components";
import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

export default function CommentDetail() {
  const { commentId } = useParams<{ commentId: string }>();

  const CommentQuery = useQuery(
    ["comment", "id", commentId],
    () => commentsApi.getCommentById(Number(commentId)),
    { enabled: !!commentId }
  );

  const post = CommentQuery.data?.data.user_post;
  if (!post || !(post.post_id && post.post_type)) {
    return <Spin indicator={<LoadingOutlined />} />;
  }

  return (
    <Post
      postId={post.post_id}
      postType={post.post_type}
      highlightComment={Number(commentId)}
    />
  );
}
