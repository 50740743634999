import { request } from "../libs/request";

const USER_ENDPOINT = "/api/user";

export interface AdminSignInDto {
  username: string;
  password: string;
}

export interface AdminSignInResponse {
  access_token: string;
  token_type: string;
}

export interface GetAllQuery {
  q?: string;
  countries?: string;
  user_type?: string;
  date_joined?: number;
  is_flagged?: boolean;
  page?: number;
  limit?: number;
  flag?: string;
  is_suspended?: boolean;
}

export const getAll = (query: GetAllQuery) =>
  request<ApiResponse<PaginatedResponse & { users?: User[] }>>(
    "GET",
    `${USER_ENDPOINT}/all_users/`,
    query
  );

export const getUsers = () => request("GET", `${USER_ENDPOINT}/all_users/`);

export const getUserById = (user_id: number) =>
  request<ApiResponse<UserDetail>>(
    "GET",
    `${USER_ENDPOINT}/admin/get_user_data`,
    {
      user_id,
    }
  );

export const adminSignIn = (adminSignInDto: AdminSignInDto) =>
  request<ApiResponse<AdminSignInResponse>>(
    "POST",
    `${USER_ENDPOINT}/admin_signin`,
    adminSignInDto
  );

export const updateStatus = (userId: number, status: boolean) =>
  request<ApiResponse>(
    "PUT",
    `${USER_ENDPOINT}/admin/user_status/${userId}?status=${status}`
  );

export const deleteById = (user_id: number) =>
  request<ApiResponse>("DELETE", `${USER_ENDPOINT}/${user_id}`);
