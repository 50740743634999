import { request } from "../libs/request";

const USER_ENDPOINT = "/api/user";

export const getCommentsByPostId = (post_id: number, post_type: number) =>
  request<ApiResponse<Comment[]>>("GET", `${USER_ENDPOINT}/get_comments/`, {
    post_id,
    post_type,
  });

export const getCommentById = (comment_id: number) =>
  request<ApiResponse<Comment>>("GET", `${USER_ENDPOINT}/get_comments/`, {
    comment_id,
  });

export const deleteComment = (comment_id: number) =>
  request<ApiResponse<Comment>>("DELETE", `${USER_ENDPOINT}/delete_comment/`, {
    comment_id,
  });
