import { useMutation } from "@tanstack/react-query";
import { notification } from "antd";
import { postApi } from "../apis";
import { queryClient } from "../libs/queryClient";

const useDeletePost = () =>
  useMutation({
    mutationFn({ post_id, post_type }: { post_id: number; post_type: number }) {
      return postApi.deletePost(post_id, post_type);
    },
    onSuccess() {
      notification.success({ message: "Post Deleted" });
      queryClient.invalidateQueries(["post"]);
    },
    onError(error: Error) {
      notification.error({ message: error.message });
    },
  });
export { useDeletePost };
