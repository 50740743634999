import { postApi, commentsApi } from "@/shared/apis";
import { ReportTypeEnum } from "@/shared/apis/reports";
import { useDeleteComment } from "@/shared/hooks/commentHooks";
import { useDeletePost } from "@/shared/hooks/postHooks";
import { useUpdateReportStatus } from "@/shared/hooks/reportHooks";
import { useIndustryQuery } from "@/shared/hooks/useIndustryQuery";
import { useInterestCategoriesQuery } from "@/shared/hooks/useInterestCategoriesQuery";
import {
  CommentOutlined,
  DeleteOutlined,
  FlagFilled,
  GlobalOutlined,
  InboxOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Carousel,
  Col,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Page from "../Page";
import Video from "../Video";

interface PostDetailProps {
  postId: number;
  postType: number;
  highlightComment?: number;
}

export default function Post({
  postId,
  postType,
  highlightComment,
}: PostDetailProps) {
  const navigate = useNavigate();

  const postQuery = useQuery(
    ["post", postId, postType],
    () => postApi.getPostById(postId, postType),
    { enabled: !!postId }
  );
  const CommentQuery = useQuery(
    ["comment", "post", postId, postType],
    () => commentsApi.getCommentsByPostId(postId, postType),
    { enabled: !!postId }
  );
  const IndustryQuery = useIndustryQuery();
  const InterestQuery = useInterestCategoriesQuery();

  const deletePost = useDeletePost();
  const updateReportStatus = useUpdateReportStatus();

  const post = postQuery.data?.data;
  const comments = CommentQuery.data?.data;

  const industry =
    post && IndustryQuery.data?.data.find((i) => i.id === post.post_id);
  const interests =
    post && InterestQuery.data?.data.filter((i) => post.tag.includes(i.id));

  useEffect(() => {
    if (!post && postQuery.isFetched) {
      navigate(-1);
    }
  }, [post, postQuery.isFetched]);

  if (postQuery.isLoading || CommentQuery.isLoading || !post) {
    return <></>;
  }

  return (
    <Page title={post.title ?? "Post detail"} onBack={() => navigate(-1)}>
      <Space
        className="w-[100%] overflow-y-scroll max-h-[600px] md:w-[70%] lg:w-[50%] box"
        size={6}
        direction="vertical"
      >
        {/* Basic info section */}

        {/* Posts section */}
        <Row>
          <Col className="mr-2">
            <Avatar size={64} src={post.profile_picture} />
          </Col>
          <Col sm={{ span: 14 }} md={{ span: 16 }}>
            <Space direction="vertical" size={0}>
              <Typography.Text className="text-md font-bold">{`${post.first_name} ${post.last_name}`}</Typography.Text>
              <Typography.Text>{`${post.job_title} at ${post.company_name}`}</Typography.Text>
              <Typography.Text>{`${post.city}, ${post.country}`}</Typography.Text>
              {industry ? (
                <Typography.Text>{`Industry: ${industry?.name}`}</Typography.Text>
              ) : (
                <></>
              )}
              <Space>
                <GlobalOutlined />
                {`${moment(post.created_date).fromNow()}, ${moment(
                  post.created_date
                ).format("D MMM YYYY")}`}
              </Space>
            </Space>
          </Col>
        </Row>
        <Row>
          {post.location ? (
            <Col span={24}>
              <Typography.Text>{post.location}</Typography.Text>
            </Col>
          ) : (
            <></>
          )}
        </Row>

        <Row className="mb-4">
          <Col span={24}>
            <Carousel>
              {post.link?.[1]?.map((i) => (
                <img key={i} className="rounded-md w-full aspect-3/4" src={i} />
              ))}

              {post.link?.[2]?.[0] ? (
                <div className="rounded-md">
                  <Video
                    src={post.link?.[2]?.[0][0]}
                    poster={post.link?.[2]?.[0][1]}
                  />
                </div>
              ) : (
                <></>
              )}
            </Carousel>
          </Col>
        </Row>
        {post.reported.length ? (
          <Row className="flex flex-wrap bg-red-10 justify-evenly text-md mb-4 rounded-sm bg-orange-200 p-2">
            <Popconfirm
              title="Are you sure to delete this post?"
              onConfirm={() =>
                deletePost.mutate(post, {
                  onSuccess() {
                    navigate(-1);
                  },
                })
              }
              okText="Yes"
              okButtonProps={{
                danger: true,
              }}
              cancelText="No"
            >
              <Button
                danger
                type="link"
                className="flex flex-col items-center p-0 border-0"
              >
                <DeleteOutlined />
                <span className="text-xs m-0">Delete</span>
              </Button>
            </Popconfirm>

            <Button
              type="link"
              className="flex flex-col items-center p-0 border-0"
              onClick={() =>
                updateReportStatus.mutate(
                  {
                    ids: post.reported.map((r) => r.id),
                    status: false,
                    type: ReportTypeEnum.POST,
                  },
                  {
                    onSuccess() {
                      navigate(-1);
                    },
                  }
                )
              }
            >
              <InboxOutlined />
              <span className="text-xs">Ignore</span>
            </Button>
            <FlagFilled className="text-lg text-red-600" />
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <Col span={24} className="text-lg text-center font-light">
            {post.title}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text>{post.desc}</Typography.Text>
          </Col>
        </Row>

        {interests ? (
          <Row className="mb-4" gutter={[4, 4]}>
            {interests.map((i) => (
              <Tag key={i.id}>{i.name}</Tag>
            ))}
          </Row>
        ) : (
          <></>
        )}

        <Row>
          <Col className="text-md" span={6}>
            <LikeOutlined className="mr-2" />
            {post.no_of_likes}
          </Col>

          <Col className="text-md" span={6}>
            <CommentOutlined className="mr-2" />
            {post.no_of_comments}
          </Col>
          <Col className="text-md text-right" push={6} span={6}>
            {post.no_of_views} Views
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text className="text-lg font-bold">
              Comments
            </Typography.Text>
          </Col>
        </Row>
        {comments?.map((c) => (
          <div
            key={c.comment_id}
            ref={(e) => {
              c.comment_id === highlightComment &&
                e &&
                e.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <Comment comment={c} commentsQuery={CommentQuery} />
          </div>
        ))}
      </Space>
    </Page>
  );
}

const Comment = ({
  comment,
  commentsQuery,
  isChild = false,
}: {
  comment: Comment;
  commentsQuery: UseQueryResult<ApiResponse<Comment[]>, unknown>;
  isChild?: boolean;
}) => {
  const isReported = comment.reported?.filter((r) => r.reported)?.length > 0;

  const updateReportStatus = useUpdateReportStatus();
  const deleteComment = useDeleteComment();

  return (
    <Row
      className={`w-full p-1.5 rounded-sm ${
        isChild
          ? "mt-2 ml-10 pt-2 pl-2 border-solid border-0 border-l-2 border-grey"
          : "mt-4"
      } ${isReported ? "bg-orange-200" : ""}`}
    >
      <Col className="mr-2">
        <Avatar src={comment?.profile_picture} />
      </Col>
      <Col sm={{ span: 12 }}>
        <Typography.Text className="text-xs text-gray-500">{`${
          comment?.first_name
        } ${comment?.last_name} • ${moment(
          comment?.created_date
        ).fromNow()}`}</Typography.Text>
        <br />
        <Typography.Text className="text-sm text-gray-700">{`${comment?.comment} `}</Typography.Text>
      </Col>
      {isReported ? (
        <Col sm={{ span: 7 }}>
          <Space>
            <Popconfirm
              title="Are you sure to delete this comment?"
              onConfirm={() => {
                deleteComment.mutate({ comment_id: comment.comment_id });
                window.location.href = "/dashboard/reports-summary";
              }}
              okText="Yes"
              okButtonProps={{
                danger: true,
              }}
              cancelText="No"
            >
              <Button
                danger
                type="link"
                className="flex flex-col items-center p-0"
              >
                <DeleteOutlined />
                <span className="text-xs m-0">Delete</span>
              </Button>
            </Popconfirm>

            <Popconfirm
              title="Are you sure to ignore this comment?"
              onConfirm={() =>
                updateReportStatus.mutate({
                  ids: comment.reported.map((r) => r.id),
                  status: false,
                  type: ReportTypeEnum.COMMENT,
                })
              }
              okText="Yes"
              okButtonProps={{
                danger: true,
              }}
              cancelText="No"
            >
              <Button
                type="link"
                className="flex flex-col items-center p-0"
                disabled={updateReportStatus.isLoading}
              >
                <InboxOutlined />
                <span className="text-xs m-0">Ignore</span>
              </Button>
            </Popconfirm>

            <FlagFilled className="text-lg text-red-600 m-0" />
          </Space>
        </Col>
      ) : (
        <></>
      )}

      {comment.child_comments?.map((c) => {
        return (
          <Comment
            key={c.comment_id}
            comment={c}
            commentsQuery={commentsQuery}
            isChild={true}
          />
        );
      })}
    </Row>
  );
};
