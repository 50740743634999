import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";

import { useQuery } from "@tanstack/react-query";
import {
  Avatar,
  Button,
  Carousel,
  Col,
  Image,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { storyApi, usersApi } from "@/shared/apis";
import { RouteKey } from "@/shared/enums";
import { Page } from "@/shared/components";
import { useDeleteStory } from "@/shared/hooks/storyHooks";
import { useUpdateReportStatus } from "@/shared/hooks/reportHooks";
import { ReportTypeEnum } from "@/shared/apis/reports";

export default function StoryDetail() {
  const navigate = useNavigate();

  const { storyId } = useParams<{ storyId: string }>();
  const StoryQuery = useQuery(
    ["story", storyId],
    () => storyApi.getStoryById(Number(storyId)),
    { enabled: !!storyId }
  );
  const story = StoryQuery.data?.data;

  const UserQuery = useQuery(
    ["user", "profile"],
    () => usersApi.getUserById(Number(story?.story_details.user_id)),
    { enabled: !!story?.story_details.user_id }
  );
  const user = UserQuery.data?.data;

  const deleteStory = useDeleteStory();
  const updateReportStatus = useUpdateReportStatus();

  if (StoryQuery.isLoading || UserQuery.isLoading) {
    return <></>;
  }

  if (!(story && story.story_details)) {
    return (
      <Navigate to={`/${RouteKey.dashboard}/${RouteKey.comments_reports}`} />
    );
  }
  return (
    <Page
      title={`Story: ${story?.story_details?.story_type
        ?.toString()
        .toUpperCase()}`}
      onBack={() => navigate(-1)}
    >
      <Space
        className="w-[100%] md:w-[70%] lg:w-[50%] box"
        size={6}
        direction="vertical"
      >
        {/* Basic info section */}
        <Row>
          <Row align="middle">
            <Avatar
              size={50}
              src={user?.intro_media_links?.profile_picture?.[0]}
              className="mr-2"
            />
            <Typography.Text className="text-lg font-medium">{`${user?.user_in.first_name} ${user?.user_in.last_name}`}</Typography.Text>
          </Row>
        </Row>
        <Row>
          <Col span={24}>
            <Carousel>
              <div>
                {[1, 3].includes(Number(story?.story_details?.media_type)) && (
                  <Image
                    className="rounded w-full "
                    src={story?.story_details?.media_link}
                  />
                )}
                {story?.story_details?.media_type === 4 && (
                  <video
                    controls
                    className=" w-full"
                    poster={story?.story_details?.video_thumbnail}
                  >
                    <source src={story?.story_details?.media_link} />
                  </video>
                )}
                {story?.story_details?.media_type === 5 && (
                  <embed
                    className="rounded  w-full "
                    src={story?.story_details?.media_link}
                  ></embed>
                )}
                {story?.story_details?.media_type === 2 && (
                  <video
                    controls
                    className=" w-full"
                    poster={story?.story_details?.video_thumbnail}
                  >
                    <source src={story?.story_details?.media_link} />
                  </video>
                )}
              </div>
            </Carousel>
          </Col>
        </Row>

        <Row className="flex flex-wrap bg-red-10 justify-evenly text-md">
          <Popconfirm
            title="Are you sure to delete this story?"
            onConfirm={() =>
              deleteStory.mutate(
                { story_id: story.story_details.id },
                {
                  onSuccess() {
                    navigate(-1);
                  },
                }
              )
            }
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
            cancelText="No"
          >
            <Button
              danger
              type="link"
              className="flex flex-col items-center p-0 border-0"
            >
              <DeleteOutlined />
              <span className="text-xs m-0">Delete</span>
            </Button>
          </Popconfirm>

          <Popconfirm
            title="Are you sure to ignore this story?"
            onConfirm={() =>
              updateReportStatus.mutate(
                {
                  ids: story.story_details.reported.map((r) => r.id),
                  status: false,
                  type: ReportTypeEnum.STORY,
                },
                {
                  onSuccess() {
                    navigate(-1);
                  },
                }
              )
            }
            okText="Yes"
            okButtonProps={{
              danger: true,
            }}
            cancelText="No"
          >
            <Button
              type="link"
              className="flex flex-col items-center p-0 border-0"
            >
              <InboxOutlined />
              <span className="text-xs">Ignore</span>
            </Button>
          </Popconfirm>
        </Row>
        <Row>
          <Typography.Text>Seen By {story.seen_by.length}</Typography.Text>
        </Row>

        {story?.seen_by?.map((i: any) => (
          <Row className="w-full" key={i?.user_id}>
            <Space className="w-full" direction="horizontal" wrap>
              <Col xs={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }}>
                <Avatar size={40} src={i?.profile_picture} />
              </Col>
              <Col sm={{ span: 14 }} md={{ span: 16 }}>
                <Typography.Text className="text-sm">{`${i?.first_name} ${i?.last_name}`}</Typography.Text>
              </Col>
            </Space>
          </Row>
        ))}
      </Space>
    </Page>
  );
}
