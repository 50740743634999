import {
  LogoutOutlined,
  UserOutlined,
  UnorderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, MenuProps, Spin } from "antd";
import clsx from "clsx";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { RouteKey } from "@/shared/enums";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";

const { Header, Content, Sider } = Layout;

const navItems: MenuProps["items"] = [
  {
    key: `/${RouteKey.dashboard}/${RouteKey.users}`,
    icon: React.createElement(UserOutlined),
    label: "Users",
  },
  {
    key: RouteKey.reports,
    icon: React.createElement(UnorderedListOutlined),
    label: "Reports",
    children: [
      {
        label: "Summary",
        key: `/${RouteKey.dashboard}/${RouteKey.reports_summary}`,
      },
      {
        label: "Users",
        key: `/${RouteKey.dashboard}/${RouteKey.users_reports}`,
      },
      {
        label: "Posts",
        key: `/${RouteKey.dashboard}/${RouteKey.posts_reports}`,
      },
      {
        label: "Comments",
        key: `/${RouteKey.dashboard}/${RouteKey.comments_reports}`,
      },
      {
        label: "Stories",
        key: `/${RouteKey.dashboard}/${RouteKey.stories_reports}`,
      },
    ],
  },
  {
    key: RouteKey.settings,
    icon: React.createElement(SettingOutlined),
    label: "Settings",
    children: [
      {
        label: "Password",
        key: `/${RouteKey.dashboard}/${RouteKey.settings_password}`,
      },
    ],
  },
  {
    key: RouteKey.logout,
    icon: React.createElement(LogoutOutlined),
    label: "Logout",
  },
];

const getBreadCrumbsFromPath = (pathname: string) => {
  if (!pathname.startsWith("/dashboard")) return [];

  return pathname.split("/").slice(1);
};

export default function MainLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);

  const handleMenuItemClick: MenuProps["onClick"] = (e) => {
    let path = "";
    if (e.key === RouteKey.logout) {
      path = `/${RouteKey.logout}`;
    } else {
      path = `${e.key}`;
    }
    navigate(path);
  };

  const breadcrumbs = getBreadCrumbsFromPath(pathname);
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={170}
        className="flex flex-col"
      >
        <Header className="px-2 flex justify-center items-center">
          {!collapsed && (
            <div className="text-md text-center text-white font-medium">
              Workideo dashboard
            </div>
          )}
        </Header>

        <Menu
          mode="inline"
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={
            pathname.includes(RouteKey.reports) ? [RouteKey.reports] : []
          }
          style={{ borderRight: 0 }}
          items={navItems}
          onClick={handleMenuItemClick}
          theme="dark"
        />
      </Sider>
      <Layout>
        <Layout className="mx-6 mb-6">
          <Breadcrumb className="my-2">
            {breadcrumbs.map((it, idx) => {
              const isLast = idx === breadcrumbs.length - 1;
              return (
                <Breadcrumb.Item key={it}>
                  <Link
                    className={clsx(
                      "capitalize",
                      isLast && "pointer-events-none"
                    )}
                    to={
                      isLast
                        ? ""
                        : pathname.substring(
                            0,
                            pathname.lastIndexOf(it) + it.length
                          )
                    }
                  >
                    {it}
                  </Link>
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
          <Content className="relative">
            {(isMutating || isFetching) && (
              <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full bg-white opacity-50 z-10">
                <Spin size="large" />
              </div>
            )}

            <div className="absolute text-[12px] top-0 left-0 w-full h-full !bg-[#f0f2f5]">
              <React.Suspense fallback={<div>Loading...</div>}>
                <Outlet />
              </React.Suspense>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
