import React from "react";
import { Navigate, RouteObject } from "react-router-dom";

import MainLayout from "@/shared/layouts/main";
import { RouteKey } from "./shared/enums";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import StoryDetail from "./pages/StoryDetail";
import CommentDetail from "./pages/CommentDetail";

const UserList = React.lazy(() => import("./pages/UserList"));
const UserDetail = React.lazy(() => import("./pages/UserDetail"));
const ReportsSummary = React.lazy(
  () => import("./pages/reports/ReportsSummary")
);
const ChangePassword = React.lazy(
  () => import("./pages/settings/ChangePassword")
);
const UsersReport = React.lazy(() => import("./pages/reports/UsersReport"));
const PostsReport = React.lazy(() => import("./pages/reports/PostsReport"));
const PostDetail = React.lazy(() => import("./pages/PostDetail"));
const CommentsReport = React.lazy(
  () => import("./pages/reports/CommentsReport")
);
const StoriesReport = React.lazy(() => import("./pages/reports/StoriesReport"));

export const routes: RouteObject[] = [
  {
    path: "",
    element: <Navigate to="dashboard" />,
  },
  {
    path: RouteKey.dashboard,
    element: <MainLayout />,
    children: [
      { index: true, element: <Navigate to="users" /> },
      {
        path: RouteKey.users,
        element: <UserList />,
      },
      {
        path: RouteKey.reports_summary,
        element: <ReportsSummary />,
      },
      {
        path: RouteKey.users_reports,
        element: <UsersReport />,
      },
      {
        path: RouteKey.posts_reports,
        element: <PostsReport />,
      },
      {
        path: RouteKey.comments_reports,
        element: <CommentsReport />,
      },
      {
        path: `${RouteKey.comments_reports}/:commentId`,
        element: <CommentDetail />,
      },
      {
        path: RouteKey.stories_reports,
        element: <StoriesReport />,
      },
      {
        path: `${RouteKey.stories_reports}/:storyId`,
        element: <StoryDetail />,
      },
      {
        path: `${RouteKey.users}/:userId`,
        element: <UserDetail />,
      },
      {
        path: `${RouteKey.posts_reports}/:postId/:postType`,
        element: <PostDetail />,
      },
      {
        path: RouteKey.settings_password,
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: RouteKey.login,
    element: <Login />,
  },
  {
    path: RouteKey.logout,
    element: <Logout />,
  },
  {
    path: "*",
    element: <>404</>,
  },
];
